// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alloy-chemical-presentation-js": () => import("/opt/build/repo/src/pages/alloy-chemical-presentation.js" /* webpackChunkName: "component---src-pages-alloy-chemical-presentation-js" */),
  "component---src-pages-checkout-page-js": () => import("/opt/build/repo/src/pages/checkout-page.js" /* webpackChunkName: "component---src-pages-checkout-page-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-graphics-js": () => import("/opt/build/repo/src/pages/instagram-graphics.js" /* webpackChunkName: "component---src-pages-instagram-graphics-js" */),
  "component---src-pages-kids-swim-merchandise-js": () => import("/opt/build/repo/src/pages/kids-swim-merchandise.js" /* webpackChunkName: "component---src-pages-kids-swim-merchandise-js" */),
  "component---src-pages-make-me-chic-email-js": () => import("/opt/build/repo/src/pages/make-me-chic-email.js" /* webpackChunkName: "component---src-pages-make-me-chic-email-js" */),
  "component---src-pages-mcv-web-rebrand-js": () => import("/opt/build/repo/src/pages/mcv-web-rebrand.js" /* webpackChunkName: "component---src-pages-mcv-web-rebrand-js" */),
  "component---src-pages-mens-comfort-decoded-merchandise-js": () => import("/opt/build/repo/src/pages/mens-comfort-decoded-merchandise.js" /* webpackChunkName: "component---src-pages-mens-comfort-decoded-merchandise-js" */),
  "component---src-pages-online-shopping-infographic-js": () => import("/opt/build/repo/src/pages/online-shopping-infographic.js" /* webpackChunkName: "component---src-pages-online-shopping-infographic-js" */),
  "component---src-pages-petite-week-merchandise-js": () => import("/opt/build/repo/src/pages/petite-week-merchandise.js" /* webpackChunkName: "component---src-pages-petite-week-merchandise-js" */),
  "component---src-pages-social-media-infographics-js": () => import("/opt/build/repo/src/pages/social-media-infographics.js" /* webpackChunkName: "component---src-pages-social-media-infographics-js" */),
  "component---src-pages-toothbrush-packaging-js": () => import("/opt/build/repo/src/pages/toothbrush-packaging.js" /* webpackChunkName: "component---src-pages-toothbrush-packaging-js" */),
  "component---src-pages-visual-narratives-js": () => import("/opt/build/repo/src/pages/visual-narratives.js" /* webpackChunkName: "component---src-pages-visual-narratives-js" */)
}

